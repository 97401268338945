import { DiscountScope, DiscountTypes } from "@/enums/discountManager";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const discountManagerTable: TableHeader[] = [
  {
    label: i18n.t("discount_manager.discount_name").toString(),
    value: "name",
    sortable: true,
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "primary-text"
  },
  {
    label: i18n.t("discount_manager.discount_scope").toString(),
    value: "scope",
    sortable: true,
    fieldComponent: TableFieldType.enumText,
    enum: DiscountScope
  },
  {
    label: i18n.t("type").toString(),
    value: "type",
    fieldComponent: TableFieldType.enumText,
    enum: DiscountTypes
  },
  {
    label: i18n.t("active").toString(),
    value: "active",
    fieldComponent: TableFieldType.bool,
    sortable: true
  }
];
