import { Discount } from "@/interfaces/discount";
import { pusherEvents } from "@/internal";
import { discountManagerTable } from "@/metadata/discountManager";
import { discountManagerActionService } from "@/services/discountManager.actions.service";
import { discountManagerService } from "@/services/discountManager.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./DiscountManagerList.template.vue";

const namespace = "DiscountManagerModule";
@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
export default class DiscountManagerListComponent extends Vue {
  public rowActions: TableAction[] = discountManagerActionService.getRowActions();
  public generalActions: ActionSubheader[] = discountManagerActionService.getGeneralActions();
  public paginate = discountManagerService.paginationAction();
  public headers = discountManagerTable;
  @Getter("discounts", { namespace })
  public tableData!: Discount[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: boolean;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("loadDiscounts", { namespace })
  private loadDiscounts!: Callback;
  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public filter(header: TableHeader) {
    discountManagerService.sortQuery(header);
  }

  protected async created() {
    this.setPageNav({
      title: "discount_manager.title",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: discountManagerService.searchEvent(),
        onClose: this.onClose
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });
    this.loadDiscounts();
    this.$changes.watch(
      [
        pusherEvents.percentageDiscountTouched,
        pusherEvents.fixedDiscountTouched
      ],
      this.loadDiscounts
    );
  }

  private onClose(update?: boolean) {
    if (update) {
      discountManagerService.searchEvent()("");
    }
  }

  private back() {
    this.$router.back();
  }
}
