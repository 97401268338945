import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import { discountManagerService } from "./discountManager.service";

class DiscountManagerActionsService {
  public getGeneralActions(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createDiscounts
          ),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "discount-manager-add"
          });
        }
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyDiscounts
          ),
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "discount-manager-edit",
            params: {
              id: arg[0].id,
              currentModel: arg[0]
            }
          });
        }
      },
      {
        icon: "fal fa-trash-alt",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.deleteDiscounts
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n
            .t("discount_manager.discount_delete_confirm")
            .toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            store
              .dispatch("DiscountManagerModule/removeDiscount", arg.item)
              .then(() => {
                EventBus.$emit("notify", {
                  text: "discount_manager.discount_deleted",
                  color: "success"
                });
              })
              .finally(arg.unselectModal);
          },
          modalCancelText: "no"
        }
      }
    ];
  }
}

export const discountManagerActionService = new DiscountManagerActionsService();
